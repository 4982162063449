<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form :model="form" :rules="rules" ref="form" style="width:600px">
      <el-form-item label="选择工厂" prop="factory_code" label-width="130px">
        <el-select :disabled="look" @change="FactorySelect" v-model="form.factory_code">
          <el-option v-for="item in factorylist" :key="item.code" :label="item.name+'('+item.sn_code+')'" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="modelShow || edit || look" label="产品型号" prop="product_model_code" label-width="130px">
        <el-select :disabled="look" @change="ModelSelect" v-model="form.product_model_code">
          <el-option v-for="item in promodellist" :key="item.product_model_code" :label="item.product_model+'('+item.sn_code+')'" :value="item.product_model_code+'&'+item.product_model"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="solieVersionShow || edit || look" label="出厂固件版本号" prop="firmware_version" label-width="130px">
        <el-select :disabled="look" v-model="form.firmware_version">
          <el-option v-for="item in solidlist" :key="item.version_code" :label="item.package_name" :value="item.version_code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="生产方式" prop="apply_type" label-width="130px">
        <el-select :disabled="look" v-model="form.apply_type">
          <el-option label="有mac在线" :value="1"></el-option>
          <el-option label="无mac在线" :value="2"></el-option>
          <el-option label="线下自烧录" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.apply_type==2&&!look" label="MAC表文件" prop="mac_file" label-width="130px">
        <el-upload
          class="upload-demo"
          :action="s3url"
          :show-file-list="false"
          :http-request="fileUpload"
          @change="handelFileChange"
          :auto-upload="false"
        >
          <el-button :disabled="look" size="small" type="info">{{filebutton}}</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item v-if="form.apply_type==2 && keyshow" label="key" label-width="130px">
        <el-input :disabled="true" v-model="form.mac_file"></el-input>
      </el-form-item>
      <el-form-item label="出厂数量" prop="num" label-width="130px">
        <el-input-number :disabled="look" v-model="form.num" :min="1"/>
      </el-form-item>
      <el-form-item label="申请标注/标题" prop="title" label-width="130px">
        <el-input :disabled="look" v-model="form.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="出厂申请说明" prop="content" label-width="130px">
        <el-input type="textarea" :disabled="look" v-model="form.content" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="接收通知邮箱" prop="apply_email" label-width="130px">
        <el-input :disabled="look" v-model="form.apply_email" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="" label-width="130px">
        <el-button v-if="edit && !look" type="primary" @click="Validate(EditApply)">修改</el-button>
        <el-button v-if="!edit && !look" type="primary" @click="Validate(SubmitApply)">提交</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../axios/productionManage'
import util from '../../util/util'
export default {
  name: '',
  props: {},
  setup () {
    return {
    }
  },
  mixins: [],
  components: {},
  data () {
    return {
      look: false,
      token: '',
      edit: false,
      filebutton: '选取文件',
      keyshow: false,
      form: {
        apply_type: '',
        factory_name: '',
        factory_code: '',
        product_model_code: '',
        product_model: '',
        num: 1,
        firmware_version: '',	
        title: '',
        content: '',
        mac_file: '',
        apply_email: '',
        region: 'gb'
      },
      rules: {
        apply_type: [{ required: true, message: '请选择生产方式', trigger: 'blur' }],
        factory_code: [{ required: true, message: '请选择工厂', trigger: 'blur' }],
        product_model_code: [{ required: true, message: '请选择产品型号', trigger: 'blur' }],
        num: [{ required: true, message: '请输入出厂数量', trigger: 'blur' }],
        firmware_version: [{ required: true, message: '请输入出厂固件版本号', trigger: 'blur' }],
        mac_file: [{ required: true, message: '请选择MAC表文件', trigger: 'blur' }],
      },
      promodellist: [],
      proModelNameCode: {},
      factorylist: [],
      fatoryNameCode: {},
      s3url: '',
      upPackage: {},
      modelShow: false,
      solieVersionShow: false,
      solidlist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    ModelSelect() {
      this.solieVersionShow = true
      this.GetSolid()
    },
    GetSolid () {
      this.api.FirmwareList({
        page: 1,
        limit: 100,
        product_model_code: this.form.product_model_code.split('&')[0],
        kname: '',
        version_code: '',
      }).then(res => {
        if (res.data.code == 200) {
          this.solidlist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    FactorySelect () {
      this.modelShow = true
      api.FactoryProModelList({
        data: {
          page: 1,
          rows: 100,
          factory_code: this.form.factory_code
        },
        payload_version: 'v1.0',
        region: 'gb'
      }).then(res => {
        if (res.data.code == 200) {
          this.promodellist = res.data.data.list
          res.data.data.list.forEach(item => {
            this.proModelNameCode[item.code] = item.name
          })
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetInfo () {
      api.ProApplyInfo({
        data: {
          token: this.token,
        },
        region: 'gb',
        payload_version: 'v1.0'
      }).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetFactory () {
      api.FactoryList({
        data: {
          page: 1,
          rows: 100,
        },
        region: 'gb',
        payload_version: 'v1.0'
      }).then(res => {
        if (res.data.code == 200) {
          this.factorylist = res.data.data.list
          res.data.data.list.forEach(item => {
            this.fatoryNameCode[item.code] = item.name
          })
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    SubmitApply() {
      api.ProApplyCreate({
        data: {
          apply_type: this.form.apply_type,
          factory_name: this.fatoryNameCode[this.form.factory_code],
          factory_code: this.form.factory_code,
          product_model_code: this.form.product_model_code.split('&')[0],
          product_model: this.form.product_model_code.split('&')[1],
          num: this.form.num,
          firmware_version: this.form.firmware_version,	
          title: this.form.title,
          content: this.form.content,
          mac_file: this.form.mac_file,
          apply_email: this.form.apply_email,
        },
        payload_version: 'v1.0'
      },{region: 'gb'}).then(res => {
        if (res.data.code == 200) {
          this.form = {}
          this.$confirm('新建成功，是否返回列表页?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.goback()
          })
          
          return
        }
        this.$message.error('新建失败' + res.data.msg)
      })
    },
    EditApply () {
      api.ProApplyUpdate({
        data: this.form,
        payload_version: 'v1.0'
      },{region: 'gb'}).then(res => {
        if (res.data.code == 200) {
          this.form = {}
          this.$confirm('修改成功，是否返回列表页?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.goback()
          })
          
          return
        }
        this.$message.error('修改失败' + res.data.msg)
      })
    },
    // 上传
    handelFileChange (event) {
      this.upPackage = event.raw
      console.log(event,'文件')
      this.GetPicturepresign()
    },
    // 预签名
    GetPicturepresign () {
      util.getFileMD5(this.upPackage, md5 => {
        let params = {
          content_length: this.upPackage.size,
          md5: md5,
          content_type: this.upPackage.type,
        }
        this.GetPreSign(params)
      })
    },
    GetPreSign (params) {
      api.ProApplyPreSign({
        data: {
          product_model_code: this.form.product_model_code.split('&')[0],
          md5: params.md5,
          content_length: params.content_length,
          content_type: params.content_type,
        },
        region: 'gb',
        payload_version: 'v1.0'
      }).then(res => {
        if (res.data.code == 200) {
          this.s3url = res.data.data.url
          this.form.mac_file = res.data.data.key
          this.fileUpload(this.upPackage)
          return
        }
        this.$message.error('预签名失败' + res.data.msg)
      }).catch(err => {
          console.log(err)
      })
    },
    fileUpload (file) {
      let config = {
        headers: {
          'Content-Type': this.upPackage.type,
          'X-Amz-Acl': 'public-read',
          'X-Amz-Server-Side-Encryption': "AES256"
        },
      }
      var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
      let url = '/aws' + this.s3url.split(urlReg.exec(this.s3url)[0])[1]
      console.log('切割域名后',this.s3url,url)
      this.$axios.put(url, file, config).then(res => {
        if (res.status === 200) {
          this.$message.success('上传成功')
          this.keyshow = true
          this.filebutton = '修改文件'
        }
      }).catch( err => {
        this.$message.error('上传失败:'+err)
      })
    },
    goback () {
      this.$router.push('/product/productionapply')
    }
  },
  filters: {},
  mounted () {
    this.token = this.$route.params.code.split('&')[0]
    if (this.token == 0) {
      this.edit = false
    } else if (this.$route.params.code.split('&')[1] == "look") {
      this.look = true
      this.GetInfo()
      this.filebutton = '修改文件'
      this.keyshow = true
    } else {
      this.GetInfo()
      this.edit = true
      this.filebutton = '修改文件'
      this.keyshow = true
    }
  },
  created () {
    this.GetFactory()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
